<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                    <div class="flex flex-wrap items-center content-between justify-between">
                        <span class="font-semibold text-base">Data Perangkat</span>
                        <div class="flex">
                            <div  class="text-xs relative mr-4"> 
                                <select name="" class="block border border-gray-200 w-24 rounded-lg text-xs" @change="getData()" v-model="tipe_perangkat">
                                    <option value="" selected>Filter</option>
                                    <option value="level" >Ketinggian Air</option>
                                    <option value="WQ">Kualitas Air</option>
                                    <option value="rain">Curah Hujan</option>
                                    <option value="weather">Cuaca</option>
                                    <option value="timelapse">Timelapse</option>
                                </select>
                            </div>
                            <div  class="text-xs relative mr-4" > 
                                <div class="absolute top-2.5 left-2.5">
                                    <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA" ></unicon>
                                </div>

                                <input
                                v-model="keyword"

                                type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword"
                                ref="keyword"
                                id="keyword"
                                @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                            </div>
                            <div>
                                <button class="flex items-center justify-center text-white rounded-full h-8 w-8 text-lg bg-green-500" @click="refres()" v-if="user.role_id == 32">
                                    <unicon name="redo" width="15" class="cursor-pointer" :class="{'animate-spin': refresh}"  fill="white"></unicon>
                                </button>
                            </div>
                        </div>
                    
                    </div>
                     <div class="mt-4 w-full overflow-x-auto">
                        <table class="w-full">
                            <thead>
                                <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                    <td class="py-4">Nama</td>
                                    <td>Lokasi</td>
                                    <td>Tipe</td>
                                    <td >Status</td>
                                    <td >GSM</td>
                                    <td >Aksi</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.id" class="border-b border-gray-200 font-semibold hover:bg-gray-100 ">
                                    <td class="py-4">
                                        <div class="flex items-center">
                                           <div class="w-8 h-8 rounded-full mr-2">
                                                <img :src="item.foto ? default_url + item.foto : default_url + 'default_sensor.png'" alt="" class="w-8 h-8 rounded-full object-cover">
                                            </div>
                                            <div>
                                                <p>{{item.name}}</p>
                                                <p class="font-semibold">{{item.code}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                       <a :href="`https://www.google.com/maps/place/${item.latitude} ${item.longitude}`" target="_blank" rel="noopener noreferrer" class="text-blue-500">{{item.latitude}} {{item.longitude}}</a>
                                    </td>
                                    <td>
                                        <span>{{item.tipe_sensor}}</span>
                                    </td>
                                    <td>
                                        <span>{{item.status == 1 ? 'Digunakan' : 'Tidak Digunakan'}}</span>
                                    </td>
                                       <td>
                                        <span>{{item.gsm_id ? item.gsm_id : ''}}</span>
                                    </td>
                                    <td class="flex items-center gap-x-2 py-4">
                                    <button class="text-white rounded h-8 w-8 text-xs bg-green-500" @click="$router.push('device/edit/'+ item.id)" v-if="status_user == 3">
                                        <unicon name="edit" width="15" class="cursor-pointer" fill="white"></unicon>
                                    </button>
                                 
                                    <button class="text-white rounded h-8 w-8 text-xs bg-red-500" @click="openDelete(item.id)" v-if="status_user == 3">
                                        <unicon name="trash-alt" width="15" class="cursor-pointer" fill="white"></unicon>
                                    </button>
                                    <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-medium hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="$router.push('device/kalibrasi/' + item.id)" v-if="item.type == 'level'">
                                        Kalibrasi
                                    </button>
                                     <button class="text-white rounded py-2 px-2 text-xs bg-blue-500" @click="$router.push('device/adjustment/' + item.id)" v-if="item.type == 'level'">
                                        Sesuaikan
                                    </button>
                                        <button class="text-white p-2 text-xs bg-red-500 rounded" @click="$router.push('/device_alert?device_id=' + item.id + '&code=' + item.devices)">
                                             Peringatan
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="flex justify-end items-center mt-4">

                            <div class="flex items-center ">
                                <div class="font-medium">
                                <span>{{startNumber}}-{{endNumber}} of {{totalRows}}</span> 
                                </div>
                                <div class="flex">
                                    <unicon name="angle-left" class="cursor-pointer" :fill="currentPage > 1 ? '#00000' : '#A1A1AA'"  @click="previousPage()"></unicon>
                                    <unicon name="angle-right" class="cursor-pointer" :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                                </div>
                            </div>

                        </div>
                    </div>
                    <deleteComponent v-on:closeModal="closeModal" v-on:deleteUser="deleteData()" v-if="modal_dialog"/>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import deleteComponent from '../../components/global/deleteComponent.vue'

export default {
    data: () => ({
           data: [],
        totalRows:100,
        perPage:10,
        limit:8,
        startNumber:1,
        endNumber:8,
        tipe_perangkat:'',
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES,
        refresh: false
    }),
    mixins:[globalMixin],
    methods:{
        getData(){
            this.axios.get('v1/device?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}&tipe_perangkat=${this.tipe_perangkat}`,this.config)
            .then((ress) => {
                this.data = ress.data.rows
                this.totalRows = ress.data.count
                 if (this.totalRows <= this.limit) {
                    this.endNumber = this.totalRows
                } else if(this.currentPage > 1 ) {
                    let page = this.currentPage - 1
                    this.startNumber = page * this.limit + 1
                    this.endNumber = this.currentPage * this.limit
                    if (this.totalRows < this.endNumber) {
                        this.endNumber = this.totalRows
                    }

                } else {
                    this.startNumber = 1
                    this.endNumber = this.limit
                }
                this.startNumber = this.totalRows < 1 ? 0 : (this.currentPage - 1 ) * this.limit + 1
            })
            .catch((err) => {
                this.errorResponse(err)
            })
        },
        openDelete(id) {
            this.delete_id = id
            this.closeModal()
        },
       
        deleteData(){
            this.axios.delete('v1/device/' + this.delete_id,this.config)
            .then((ress) => {
                 this.closeModal()
                 this.getData()
                  this.$snack.success({
                    text: ress.data.message,

                })
            })
        },
         closeModal() {
            
            this.modal_dialog = !this.modal_dialog
        },
        refres() {
            this.refresh = true;
            this.axios.get('v1/device/get',this.config)
            .then((ress) => {
                
                this.refresh = false;
                this.$snack.success({
                    text: ress.data.message,
                })
            })
            .catch((err) => {
                this.refresh = false;
                this.$snack.error({
                    text: ress.data.message,
                })
                
                console.log(err.response.data.message)
            })
        },
         eventChange(event){
             this.default_url = ''
           const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load',()=>{
                this.data.foto=fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
       },
    
    },
    computed:{
        ...mapGetters({
            user:'auth/user',
        }),
       
    },
    created() {
        this.getData()
    },
    components:{
        deleteComponent
    }
}
</script>